import { ReactNode } from "react"

export const isDevEnvironment = (): boolean =>
  process && process.env.NODE_ENV === "development"

// TODO: Remove this to use the real domain when multi-domain concept is ready
export const getCurrentDomain = (): string =>
  isDevEnvironment() ? "http://localhost:3030" : "https://www.gzb.ae"

// Nav links for footer and menu drawer
export const navLinks: {
  href: string
  label: string
}[] = [
  {
    href: "/g/about",
    label: "About Us",
  },
  {
    href: "/g/contact",
    label: "Contact Us",
  },
  {
    href: "/g/payments",
    label: "Payment Methods",
  },
  {
    href: "/g/faq",
    label: "FAQs",
  },
  {
    href: "/g/shipping",
    label: "Shipping Policy",
  },
  {
    href: "/g/refund",
    label: "Refund and Return",
  },
  {
    href: "/g/terms",
    label: "Terms of Use",
  },
  {
    href: "/g/privacy",
    label: "Privacy Policy",
  },
  {
    href: "/g/cookie",
    label: "Cookie Notice",
  },
]

type ConditionalWrapperProps = {
  condition: boolean
  wrapper: (children: ReactNode) => JSX.Element
  children: JSX.Element
}
export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) => (condition ? wrapper(children) : children)

// Fisher Yates Shuffle
// https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
export const shuffle = ([...array]) => {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

export const fetcher = (url: string) => fetch(url).then((res) => res.json())
