import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/700.css"
import "@theme/snipcart/styles.css"

import {
  ChakraProvider,
  Heading,
  HeadingProps,
  Link,
  LinkProps,
  ListItem,
  ListItemProps,
  ListProps,
  OrderedList,
  Text,
  TextProps,
  UnorderedList,
} from "@chakra-ui/react"
import { EnchantProvider } from "@common/Enchant"
import { SnipcartProvider } from "@common/Snipcart"
import { MDXProvider } from "@mdx-js/react"
import theme from "@theme/chakra/theme"
import { isDevEnvironment } from "@utils/common"
import type { AppProps } from "next/app"
import NextLink from "next/link"
import Script from "next/script"
import { DefaultSeo } from "next-seo"

const components = {
  h1: (props: HeadingProps) => (
    <Heading
      as={"h1"}
      fontSize={"xl"}
      mt={4}
      {...props}
    />
  ),
  h2: (props: HeadingProps) => (
    <Heading
      as={"h2"}
      fontSize={"md"}
      mt={4}
      {...props}
    />
  ),
  h3: (props: HeadingProps) => (
    <Heading
      as={"h3"}
      fontSize={"sm"}
      mt={4}
      {...props}
    />
  ),
  ul: (props: ListProps) => <UnorderedList {...props} />,
  ol: (props: ListProps) => <OrderedList {...props} />,
  li: (props: ListItemProps) => (
    <ListItem
      mt={2}
      ms={4}
      {...props}
    />
  ),
  p: (props: TextProps) => (
    <Text
      mt={4}
      {...props}
    />
  ),
  a: (props: LinkProps) => (
    <Link
      as={NextLink}
      color={"blue.600"}
      textDecoration={"underline"}
      textDecorationStyle={"dotted"}
      textUnderlineOffset={"0.3em"}
      _hover={{
        textDecoration: "none",
      }}
      {...props}
    />
  ),
}

function MyApp({ Component, pageProps }: AppProps) {
  const isDev = isDevEnvironment()

  // TODO: It seems like with DefaultSeo from next-seo, there's a bug introduced when using InstantSearch
  // It will not override the defaults for example noindex, nofollow, etc.
  // So for now, we'll just disable it for the search page
  const isSearch = Component.name === "Search"

  return (
    <MDXProvider components={components}>
      <ChakraProvider theme={theme}>
        <EnchantProvider>
          <SnipcartProvider defaultLang={"en"}>
            {!isSearch && (
              <DefaultSeo
                defaultTitle={
                  "gzb.ae | Largest Middle-East Bookstore with Millions of Books - Turbo deliveries available for Dubai, Abu Dhabi, Sharjah and other Emirates"
                }
                titleTemplate={"%s | gzb.ae"}
                description={
                  "We catalog books from all over the world. With a collection of over 20 Million books, you can find the book you are looking for. We offer fast delivery to all over Dubai, Abu Dhabi, Sharjah and other Emirates."
                }
                additionalMetaTags={[
                  {
                    name: "viewport",
                    content: "width=device-width, initial-scale=1.0",
                  },
                ]}
                facebook={{
                  appId: `${process.env.NEXT_PUBLIC_FB_APP_ID}`,
                }}
                openGraph={{
                  type: "website",
                  locale: "en_AE",
                  url: "https://www.gzb.ae/",
                  siteName: "gzb.ae",
                  images: [
                    {
                      url: `${process.env.NEXT_PUBLIC_IMAGE_CDN}/images/og-gzb.png?class=jpg`,
                      width: 1200,
                      height: 630,
                      alt: "gzb.ae | Largest Middle-East Bookstore with Millions of Books",
                    },
                  ],
                }}
                twitter={{
                  site: "@gzb_ae",
                  cardType: "summary_large_image",
                }}
              />
            )}
            <Component {...pageProps} />
            {!isDev && (
              <>
                {/* Zaraz */}
                <Script
                  src={"https://_z.gzb.ae/cdn-cgi/zaraz/i.js"}
                  strategy={"afterInteractive"}
                />
              </>
            )}
          </SnipcartProvider>
        </EnchantProvider>
      </ChakraProvider>
    </MDXProvider>
  )
}

export default MyApp
