import { extendTheme } from "@chakra-ui/react"
import colors from "@theme/chakra/theme/foundations/colors"
import components from "@theme/chakra/theme/foundations/components"
import config from "@theme/chakra/theme/foundations/config"
import fonts from "@theme/chakra/theme/foundations/fonts"
import styles from "@theme/chakra/theme/foundations/styles"

const overrides = {
  config,
  fonts,
  colors,
  styles,
  components,
}

const theme = extendTheme(overrides)

export default theme
