// eslint-disable-next-line import/no-anonymous-default-export
export default {
  black: "#1c1c1c",
  // Brand - Light Blue
  yellow: {
    "50": "#FFF6E5",
    "100": "#FFE5B8",
    "200": "#FFD48A",
    "300": "#FFC35C",
    "400": "#FFB22E",
    "500": "#FFA100",
    "600": "#CC8100",
    "700": "#996100",
    "800": "#664100",
    "900": "#332000",
  },
}
