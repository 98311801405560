import { StyleFunctionProps } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  global: (props: StyleFunctionProps) => ({
    body: {
      background: mode("white", "black")(props),
      lineHeight: "base",
      transition: "none",
      scrollbarColor: `gray.200 transparent`,
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-track": {
        width: "4px",
        backgroundColor: "gray.200",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "yellow.400",
        borderRadius: "4px",
      },
    },
    mark: {
      bgColor: "transparent",
      color: "yellow.500",
      fontWeight: "700",
    },
  }),
}
