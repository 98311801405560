// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Radio: {
    parts: ["label"],
    baseStyle: {
      label: {
        width: "100%",
      },
    },
  },
}
