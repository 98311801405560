import Script from "next/script"
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"

type StateProps = {
  chatAvailable: boolean
}

const initialState: StateProps = {
  chatAvailable: false,
}
const EnchantContext = createContext(initialState)

export const EnchantProvider = (props: { children: ReactNode }) => {
  const [state, setState] = useState(initialState)
  const { children } = props

  useEffect(() => {
    const setIframeTitle = () => {
      const enchantIframe = document.querySelector(
        "#enchant-messenger-main iframe"
      )
      enchantIframe?.setAttribute("tabindex", "-1")
      enchantIframe?.setAttribute("title", "gzb.ae Chat Support")
    }
    const listenEnchant = () => {
      window.addEventListener("enchant.loaded", () => {
        const chatAvailable = window.enchant.messenger.chatAvailable()
        setState({ chatAvailable: chatAvailable })
        window.enchant.messenger.onChatAvailableChange(function (available) {
          setState({ chatAvailable: available })
          if (available) {
            setIframeTitle()
          }
        })
      })
    }

    if (typeof window.enchant.messenger !== "undefined") {
      const chatAvailable = window.enchant.messenger.chatAvailable()
      setState({ chatAvailable: chatAvailable })
      window.enchant.messenger.onChatAvailableChange(function (available) {
        setState({ chatAvailable: available })
        if (available) {
          setIframeTitle()
        }
      })
    } else {
      listenEnchant()
    }
  }, [])

  return (
    <>
      <Script
        id={"enchant"}
        strategy={"afterInteractive"}
        type={"text/javascript"}
        dangerouslySetInnerHTML={{
          __html: `
                var enchant = enchant || [];
                enchant.push(function(){
                  enchant.messenger.config({
                    showKnowledgeBase: false,
                    showLauncher: false,
                  });
                  window.dispatchEvent( new Event('enchant.loaded') );
                })
          `,
        }}
      />
      <Script
        src={"//platform.enchant.com"}
        data-enchant-messenger-id={"7b31159f"}
        strategy={"afterInteractive"}
      />
      <EnchantContext.Provider value={state}>
        {children}
      </EnchantContext.Provider>
    </>
  )
}

export const useEnchant = () => useContext(EnchantContext)
